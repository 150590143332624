import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import i18n from 'i18next'
import { I18nextProvider } from 'react-i18next'
const storedLanguage = localStorage.getItem('language')
const defaultLanguage = storedLanguage || 'en'
i18n.init({
  interpolation: { escapeValue: false }, // React already escapes values by default
  lng: defaultLanguage, // Set the default language here
  resources: {
    en: {
      translation: require('./locales/en.json'),
    },
    fr: {
      translation: require('./locales/fr.json'),
    },
    // Add more languages and their respective translations here
  },
})
createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
